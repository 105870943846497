<template>
  <tp-modal name="modal-alert" width="400px">
    <v-card slot-scope="props">
      <v-card-title
        class="modal-alert--title font-weight-bold"
        v-html="props.payload.title"
      >
        {{ props.payload.title }}
      </v-card-title>

      <v-card-text
        class="text-body-1 grey--text text--darken-4"
        v-html="props.payload.message"
      >
        {{ props.payload.message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            props.payload.showCancelBtn ? props.payload.showCancelBtn : true
          "
          text
          color="grey darken-1"
          @click="
            closeModal(
              props.payload.onCancelHandler
                ? props.payload.onCancelHandler
                : undefined
            )
          "
        >
          {{
            props.payload.cancelBtnText ? props.payload.cancelBtnText : "Hủy"
          }}
        </v-btn>
        <v-btn
          v-if="props.payload.confirmBtn"
          text
          :color="props.payload.confirmBtn && props.payload.confirmBtn.color"
          @click="
            clickHandler(
              props.payload.confirmBtn &&
                props.payload.confirmBtn.onClickHandler
            )
          "
        >
          {{ props.payload.confirmBtn && props.payload.confirmBtn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  methods: {
    clickHandler(onClickHandler) {
      this.closeModal();

      if (typeof onClickHandler === "function") {
        onClickHandler();
      }
    },
    closeModal(onCancelHandler) {
      this.$modal.hide({ name: "modal-alert" });

      if (typeof onCancelHandler === "function") {
        onCancelHandler();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-alert--title {
  word-break: normal;
}
</style>
