var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tp-modal',{attrs:{"name":"modal-alert","width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('v-card',{},[_c('v-card-title',{staticClass:"modal-alert--title font-weight-bold",domProps:{"innerHTML":_vm._s(props.payload.title)}},[_vm._v(" "+_vm._s(props.payload.title)+" ")]),_c('v-card-text',{staticClass:"text-body-1 grey--text text--darken-4",domProps:{"innerHTML":_vm._s(props.payload.message)}},[_vm._v(" "+_vm._s(props.payload.message)+" ")]),_c('v-card-actions',[_c('v-spacer'),(
          props.payload.showCancelBtn ? props.payload.showCancelBtn : true
        )?_c('v-btn',{attrs:{"text":"","color":"grey darken-1"},on:{"click":function($event){return _vm.closeModal(
            props.payload.onCancelHandler
              ? props.payload.onCancelHandler
              : undefined
          )}}},[_vm._v(" "+_vm._s(props.payload.cancelBtnText ? props.payload.cancelBtnText : "Hủy")+" ")]):_vm._e(),(props.payload.confirmBtn)?_c('v-btn',{attrs:{"text":"","color":props.payload.confirmBtn && props.payload.confirmBtn.color},on:{"click":function($event){return _vm.clickHandler(
            props.payload.confirmBtn &&
              props.payload.confirmBtn.onClickHandler
          )}}},[_vm._v(" "+_vm._s(props.payload.confirmBtn && props.payload.confirmBtn.text)+" ")]):_vm._e()],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }